// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import './colors.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$orca-app-primary: mat.define-palette(mat.$indigo-palette);
$orca-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$orca-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$orca-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $orca-app-primary,
      accent: $orca-app-accent,
      warn: $orca-app-warn
    )
  )
);

/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--scroll-grey);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-blue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-blue);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($orca-app-theme);

html,
body {
  height: 100%;
  color: var(--black);
}
body {
  margin: 0;
  font-family: 'poppins', 'Helvetica Neue', sans-serif;
  background-color: var(--background);
}
p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

a {
  color: var(--blue);
  font-weight: bold;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

pre {
  margin: 0px;
  white-space: pre-wrap;
  font-family: 'poppins', 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

.main {
  display: grid;
  grid-template-columns: 200px 0 1fr;
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}

.break-all {
  word-break: break-all;
}

.u-link {
  color: var(--blue);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.color-red {
  color: var(--red);
}

.color-blue {
  color: var(--blue);
}

.color-light-blue {
  color: var(--light-blue);
}

.overflow-hidden {
  overflow: hidden;
}

.visible-xs {
  display: none;
  @media only screen and (max-width: 992px) {
    display: block;
  }
}

.hidden-xs {
  display: block;
  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.u-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@font-face {
  font-family: 'omegle';
  src: url('assets/fonts/OMEGLE.eot');
  src: url('assets/fonts/OMEGLE.woff') format('woff'),
    url('assets/fonts/OMEGLE.ttf') format('truetype'),
    url('assets/fonts/OMEGLE.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins';
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'poppins';
  src: url('assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'poppins';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('assets/fonts/Nunito-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Nunito';
  src: url('assets/fonts/Nunito-Italic-VariableFont_wght.ttf');
  font-style: italic;
}

.u-relative {
  position: relative;
}

.u-bold {
  font-weight: bold;
}

.u-nocontent-text {
  text-align: center;
  padding: 60px 30px;
}

.u-circle-image {
  border-radius: 50%;
}

.app-login {
  position: fixed;
  width: 100%;
  height: 100vh;
}

.u-content {
  padding: 20px 30px 30px 30px;
  @media only screen and (max-width: 992px) {
    padding: 20px 18px;
  }
  @media only screen and (max-width: 520px) {
    padding: 10px 8px;
  }
  &--has-fixed-buttons {
    @media only screen and (max-width: 992px) {
      padding: 20px 18px 90px;
    }
    @media only screen and (max-width: 520px) {
      padding: 20px 8px 90px;
    }
  }
}

.u-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-subtitle {
  font-size: 16px;
  font-weight: 600;
  i {
    margin-right: 5px;
  }
}

.u-fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: var(--light-grey-2);
  border-radius: 12px;
  padding: 15px 15px 25px;
  background-color: var(--white);
  @media only screen and (max-width: 992px) {
    padding: 5px 8px 15px;
  }
  &--tabs {
    border-radius: 0 0 8px 8px;
  }
}

.u-legend {
  font-size: 18px;
  padding: 0 10px;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.u-error {
  font-size: 12px;
  color: var(--red);
  margin: 5px 0;
}

.mat-datepicker-toggle,
.mat-datepicker-content {
  display: none;
}

.mat-select-trigger {
  height: 38px;
}

.u-required {
  font-weight: normal;
  font-size: 12px;
}

.mat-dialog-container {
  padding: 20px !important;
}

.mat-dialog-content {
  margin: 0px !important;
  padding: 0px !important;
}

.mat-horizontal-stepper-header {
  padding: 0px 0px !important;
  height: 50px !important;
  pointer-events: none !important;
}
.mat-horizontal-content-container {
  padding: 0 0px 0px 0px !important;
}

.mat-horizontal-stepper-header-container {
  border-bottom: solid 2px var(--light-grey-2);
}

.mat-step-header:last-child .mat-step-icon-selected,
.mat-step:last-child .mat-step-icon-selected {
  background-color: var(--green) !important;
}

.mat-vertical-content {
  @media only screen and (max-width: 992px) {
    padding: 0 24px 0 0 !important;
  }
}
.mat-vertical-stepper-header {
  height: 0 !important;
}

/* INPUTS */
textarea {
  resize: none;
  min-height: 100px;
}

.u-input {
  &__tooltip {
    font-size: 16px;
  }
  &__text {
    margin: 3px 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--medium-blue);
  }
  &__content {
    color: var(--dark-grey);
    position: relative;
    padding: 0 15px;
    border: 1px solid var(--light-blue);
    border-radius: 50px;
    max-height: 42px;
    font-weight: normal;
    &.is-incorrect {
      border: 1px solid var(--red);
    }
    &--icon {
      background: url('/assets/img/calendar.svg') center right 10px no-repeat;
      background-size: 20px;
      padding-right: 40px;
    }
    &--icon-time {
      background: url('/assets/img/clock.svg') center right 10px no-repeat;
      background-size: 20px;
      padding-right: 40px;
    }
  }
  &__input {
    font-size: 14px;
    color: var(--dark-grey);
    width: 100%;
    border: none;
    outline-width: 0;
    height: 38px;
    background: transparent;
    ::placeholder {
      position: relative;
      color: var(--dark-grey);
      opacity: 0.5;
    }
    &.is-disabled {
      color: var(--black-transparency);
    }
    .mat-mdc-select-trigger {
      padding-top: 7px;
      font-family: 'Arial';
      font-size: 14px;
    }
  }
  &__textarea {
    width: 100%;
    border: 1px solid var(--light-blue);
    font-size: 14px;
    font-family: 'Arial';
    color: var(--dark-grey);
    width: 100%;
    outline-width: 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 18px;
    &::placeholder {
      position: relative;
      color: var(--dark-grey);
    }
  }
  &__checkbox {
    position: relative;
    padding: 2px 0 0 26px;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: var(--white);
      border: 2px solid var(--light-blue);
      border-radius: 2px;
    }

    input:checked ~ .u-input__checkbox-checkmark {
      background: var(--light-blue) url('/assets/img/icon-checkbox.svg') center;
      border: 2px solid var(--light-blue);
    }

    .u-input__checkbox-checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    input:checked ~ .u-input__checkbox-checkmark:after {
      display: block;
    }

    .u-input__checkbox-checkmark:after {
      top: 5px;
      left: 5px;
      width: 22px;
      height: 22px;
    }
  }
}

/*TABLE*/

.u-table {
  margin-top: 10px;
  &__header {
    display: grid;
    background-color: var(--table-header);
    color: var(--white);
    padding: 8px;
    text-align: center;
  }
  &__data {
    display: grid;
    padding: 10px;
    align-items: center;
    text-align: center;
    background-color: var(--white);
    border-bottom: solid 1px var(--light-grey-2);
    cursor: pointer;
  }
  &__no_results {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    background-color: var(--white);
    margin-top: 5px;
    box-shadow: 0px 3px 12px -3px var(--black-transparency);
  }
  &__data:hover {
    background-color: var(--table-hover);
  }
  &__square_image img {
    padding: 5px;
    width: 90px;
    height: 90px;
    @media only screen and (max-width: 992px) {
      width: 90px;
      height: 90px;
    }
  }
  &__circle_image img {
    padding: 5px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    @media only screen and (max-width: 992px) {
      width: 90px;
      height: 90px;
    }
  }
}

/*FILTERS*/

.u-filters {
  &__container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  &__form {
    width: 100%;
  }
  &__content {
    display: flex;
    gap: 18px;
    align-items: flex-end;
    .u-input {
      flex: 1;
    }
    &--blocks {
      flex-direction: column;
      align-items: inherit;
    }
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      align-items: inherit;
      gap: 10px;
    }
  }
  &__buttons {
    display: grid;
    grid-template-columns: 125px 115px;
    justify-content: flex-end;
    gap: 10px;
  }
  &__button {
    display: none;
    @media only screen and (max-width: 992px) {
      display: block;
    }
  }
}

.u-list__total {
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  padding-top: 24px;
  &-xs {
    padding-top: 10px;
    text-align: center;
  }
}

/* ACTIONS */

.u-actions {
  width: 100%;
  &__container {
    margin: 10px 10px 10px 0;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
}

/*DETAILS */

.u-detail {
  &__button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    text-align: right;
    @media only screen and (max-width: 992px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px 15px;
      box-sizing: border-box;
      background: var(--white);
      box-shadow: 0px 3px 12px -3px var(--black-transparency);
      align-items: center;
    }
    &-left {
      text-align: left;
      & button {
        margin-right: 30px;
      }
    }
  }
}

/*TOAST*/

/* Bottom on mobile */
.cdk-overlay-pane.mat-snack-bar-handset {
  @media only screen and (max-width: 992px) {
    margin-bottom: 0;
    margin-top: auto !important;
  }
}
/* !Bottom on mobile */

.mat-snack-bar-container {
  padding: 40px 25px !important;
}

.mat-simple-snackbar {
  font-size: 20px !important;
}

.toast {
  &-base {
    background-color: var(--toast-back);
    color: var(--toast-font);
    .mat-simple-snackbar-action {
      color: var(--toast-font);
    }
  }
  &-success {
    background-color: var(--toast-s-back);
    color: var(--toast-s-font);
    .mat-simple-snackbar-action {
      color: var(--toast-s-font);
    }
  }
  &-error {
    background-color: var(--toast-e-back);
    color: var(--toast-e-font);
    .mat-simple-snackbar-action {
      color: var(--toast-e-font);
    }
  }
  &-warning {
    background-color: var(--toast-w-back);
    color: var(--toast-w-font);
    .mat-simple-snackbar-action {
      color: var(--toast-w-font);
    }
  }
}

.mat-tooltip {
  font-size: 14px;
  line-height: 22px;
}

/*OVERRIDE CHARTS*/

.ngx-charts .label {
  font-size: 20px !important;
}

.ngx-charts .label {
  @media only screen and (max-width: 992px) {
    font-size: 14px !important;
  }
}

/* IMAGE CROPPER */
.image-cropper {
  max-height: 80vh;
}

/* FULL CALENDAR */
.fc .fc-toolbar-title {
  @media only screen and (max-width: 768px) {
    font-size: 1em !important;
    line-height: 1.2em;
    text-align: center;
  }
}

.fc .fc-toolbar {
  @media only screen and (max-width: 768px) {
    gap: 10px;
  }
}

.fc .fc-button-primary {
  height: 46px !important;
  padding: 5px 10px !important;
  background: var(--light-blue) !important;
  color: var(--white) !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  background-color: var(--green-2) !important;
  &:hover {
    background-color: var(--green-3) !important;
  }
}

.fc-h-event {
  background-color: var(--light-blue) !important;
  border: 1px solid var(--light-blue) !important;
}

.fc .fc-button-group {
  gap: 10px;
}

.fc .fc-toolbar-title {
  text-transform: uppercase;
}

.fc .fc-button {
  @media only screen and (max-width: 768px) {
    line-height: 1.2em !important;
  }
}

.fc .fc-view-harness {
  @media only screen and (max-width: 768px) {
    min-height: 500px;
  }
}

.fc-daygrid a {
  cursor: initial;
  text-transform: capitalize;
  color: var(--black);
  &:hover {
    text-decoration: none;
  }
}

.u-event {
  background-color: var(--blue-2);
  white-space: initial !important;
  align-items: flex-start !important;
  cursor: pointer !important;
  .fc-daygrid-event-dot {
    //LA BOLITA
    border-color: var(--red);
    margin-top: 5px;
  }
  .fc-event-title {
    word-break: break-word;
    color: var(--white);
    @media only screen and (max-width: 768px) {
      overflow: hidden !important;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .fc-event-time {
    color: var(--white);
  }
}
