:root {
  --black: #333333;
  --black-back: #373434;
  --white: #ffffff;
  --red: #bb1941;
  --light-blue: #36b3e7;
  --medium-blue: #276ea5;
  --blue: #3f51b5;
  --blue-2: #004085;
  --green: #1c9744;
  --green-2: #28a745;
  --green-3: #218838;
  --light-grey: #f2f2f2;
  --light-grey-2: #d2d5d7;
  --medium-grey: #bdbdbd;
  --grey: #9f9d9d;
  --dark-grey: #363636;
  --orange: #fb912d;
  --pink: #ff76c8;
  --black-transparency: rgba(0, 0, 0, 0.38);
  --button-disabled: rgba(15, 112, 183, 0.5);
  --menu-grey: #cacaca;
  --menu-dark-grey: #5a5454;
  --scroll-blue: #17a2b8;
  --scroll-grey: grey;
  --table-header: #373434;
  --table-hover: #d6effd;
  --toast-back: #cce5ff;
  --toast-font: #004085;
  --toast-s-back: #d4edda;
  --toast-s-font: #155724;
  --toast-e-back: #f8d7da;
  --toast-e-font: #721c24;
  --toast-w-back: #fff3cd;
  --toast-w-font: #856404;
  --background: #f7f7f7;
  --landing-red: #f04336;
  --landing-blue: #004f9c;
  --landing-light-blue: #0a303a;
  --landing-light-grey: #dedede;
  --landing-grey: #666666;
  --landing-black: #181a21;
  --landing-white: #f7f7f7;
  --button-primary: #36b3e7;
  --button-primary-hover: #276ea5;
  --button-secondary: #17a2b8;
  --button-secondary-hover: #138496;
  --button-success: #28a745;
  --button-success-hover: #218838;
  --button-danger: #dc3545;
  --button-danger-hover: #c82333;
  --button-third: #ffffff;
  --button-third-hover: #d5f7ff;
}
